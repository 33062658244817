<script>
import ajax from "./ajax";
import UploadDragger from "./upload-dragger.vue";
import ElProgress from 'element-ui/packages/progress';
export default {
  inject: ["uploader"],
  components: {
    UploadDragger,
    ElProgress
  },
  props: {
    progressValue: {
      type: Number,
      default: 0
    },
    showFileList: {
      type: Boolean
    },
    progressWidth: {
      type: Number,
      default: 100
    },
    // 单个文件大小
    filesSizeMax: {
      type: Number,
      default: 1024
    },
    progressType: {
      type: String,
      default: "circle"
    },
    progressTips: {
      type: String,
      default: ""
    },
    // 金山云input节点id
    dcdom: {
      type: String,
      default: "dcFile"
    },
    // 金山云初始化配置
    dcConfig: {
      type: Object
    },
    // 金山云上传初始化接口函数
    dcUploadInit: {
      type: Object
    },
    // 金山云是否自动上传
    dcAutoUpload: Boolean,
    type: String,
    action: {
      type: String,
    },
    name: {
      type: String,
      default: "file"
    },
    data: Object,
    headers: Object,
    withCredentials: Boolean,
    multiple: Boolean,
    accept: String,
    onStart: Function,
    onProgress: Function,
    onSuccess: Function,
    onError: Function,
    beforeUpload: Function,
    drag: Boolean,
    onPreview: {
      type: Function,
      default: function () { }
    },
    onRemove: {
      type: Function,
      default: function () { }
    },
    fileList: Array,
    autoUpload: Boolean,
    listType: String,
    httpRequest: {
      type: Function,
      default: ajax
    },
    disabled: Boolean,
    limit: Number,
    onExceed: Function
  },
  data() {
    return {
      timer: null,
      percentageNumber: 0,
      progressTrue: false,
      // 金山云上传文件信息(type,size,name)
      dcUploadData: {},
      domsize: '',
      mouseover: false,
      reqs: {}
    };
  },

  methods: {
    isImage(str) {
      return str.indexOf("image") !== -1;
    },
    handleChange(ev) {
      this.domsize = ev.target.id;
      const files = ev.target.files;
      this.$parent.dcChange(files[0]);
      if (!files) return;
      this.uploadFiles(files);
    },
    // 上传文件限制
    uploadLimit(files) {
      if (!this.accept) return true;
      let fileName = files[files.length - 1].name;
      let str = fileName.substring(fileName.length - 5);
      if (str[0] != '.') {
        str = fileName.substring(fileName.length - 4);
      }
      let array = this.accept.split(',');
      if (array.indexOf(str.toLowerCase()) != -1) return true;
      return false;
    },
    uploadFiles(files, dom) {
      if (this.limit && this.fileList.length + files.length > this.limit) {
        this.$message({ message: '上传文件超过了限制', type: 'warning' });
        this.onExceed && this.onExceed(files, this.fileList);
        return;
      }
      let uploadFlag = this.uploadLimit(files);
      if (!uploadFlag) return this.$message({ message: `上传文件类型:只支持${this.accept}`, type: 'warning' });
      let postFiles = Array.prototype.slice.call(files);
      if (!this.multiple) {
        postFiles = postFiles.slice(0, 1);
      }

      if (postFiles.length === 0) {
        return;
      }
      // 阳江的四名模块临时解除size限制2024年1月30日
      // let resMax = this.fileMaxSizeEvnet(postFiles);
      // if (!resMax) {
      //   return false;
      // };
      const before = !this.beforeUpload || this.beforeUpload(files);
      if (!before) return;
      postFiles.forEach(rawFile => {
        this.onStart(rawFile);
      });
      if (this.dcAutoUpload && before) this.post(files);
    },
    // 校验文件大小
    fileMaxSizeEvnet(postFiles) {
      // let imgMaxSize = 1024; // 10MB
      let imgMaxSize = this.filesSizeMax; // 10MB
      if (postFiles[0].size > imgMaxSize * 1024 * 1024) {
        this.$message({
          message: "请上传大小不超过【" + imgMaxSize + "MB】",
          type: "warning",
        });
        return false;
      };
      return true;
    },
    upload(rawFile) {
      this.beforeUpload();
      this.$refs.input.value = null;
      if (!this.beforeUpload) {
        this.$parent.dcChange(files[0]);
        return this.post(rawFile);
      }
      if (before && before.then) {
        // before.then(
        //   processedFile => {
        //     const fileType = Object.prototype.toString.call(processedFile);

        //     if (fileType === "[object File]" || fileType === "[object Blob]") {
        //       if (fileType === "[object Blob]") {
        //         processedFile = new File([processedFile], rawFile.name, {
        //           type: rawFile.type
        //         });
        //       }
        //       for (const p in rawFile) {
        //         if (rawFile.hasOwnProperty(p)) {
        //           processedFile[p] = rawFile[p];
        //         }
        //       }
        //       this.post(processedFile);
        //     } else {
        //       this.post(rawFile);
        //     }
        //   },
        //   () => {
        //     this.onRemove(null, rawFile);
        //   }
        // );
      } else if (before !== false) {
        this.post(rawFile);
      } else {
        this.onRemove(null, rawFile);
      }
    },
    abort(file) {
      const { reqs } = this;
      if (file) {
        let uid = file;
        if (file.uid) uid = file.uid;
        if (reqs[uid]) {
          reqs[uid].abort();
        }
      } else {
        Object.keys(reqs).forEach(uid => {
          if (reqs[uid]) reqs[uid].abort();
          delete reqs[uid];
        });
      }
    },
    post(rawFile) {
      console.log(rawFile);
      if (rawFile[0].type == "video/mp4") {
        var url = URL.createObjectURL(rawFile[0]);
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", _event => {
          let time_length = parseInt(audioElement.duration);
          console.log(time_length, "视频时长");

          this.$parent.dcUploadHttp(this.domsize, time_length);
          this.progressTrue = true;
          this.percentageNumber = this.progressValue;
          this.progressInit();
        });
      } else {
        this.$parent.dcUploadHttp(this.domsize, "");
        this.progressTrue = true;
        this.percentageNumber = this.progressValue;
        this.progressInit();
      }
    },
    // 上传中
    progressInit() {
      this.timer = setInterval(() => {
        this.percentageNumber += 9;
        if (this.percentageNumber > 90) {
          clearInterval(this.timer);
        }
      }, 180);
    },
    // 上传结束
    progressEnd() {
      clearInterval(this.timer);
      this.progressTrue = false;
    },
    handleClick() {
      if (!this.disabled) {
        this.$refs.input.value = null;
        this.$refs.input.click();
      }
    },
    handleKeydown(e) {
      if (e.target !== e.currentTarget) return;
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.handleClick();
      }
    }
  },

  render(h) {
    let {
      showFileList,
      progressWidth,
      progressTrue,
      progressType,
      progressTips,
      percentageNumber,
      dcdom,
      dcConfig,
      handleClick,
      drag,
      name,
      handleChange,
      multiple,
      accept,
      listType,
      uploadFiles,
      disabled,
      handleKeydown,
      progressnum

    } = this;
    const data = {
      class: {
        "el-upload": true
      },
      on: {
        click: handleClick,
        keydown: handleKeydown
      }
    };
    data.class[`el-upload--${listType}`] = true;
    return (
      <div {...data} tabindex="0">
        {drag ? (
          <upload-dragger disabled={disabled} on-file={uploadFiles}>
            {this.$slots.default}
          </upload-dragger>
        ) : (
          this.$slots.default
        )}
        <input
          id={dcdom}
          class="el-upload__input"
          type="file"
          ref="input"
          name={name}
          on-change={handleChange}
          multiple={multiple}
          accept={accept}
        />
        {progressTrue && !showFileList ? (
          <div>
            <el-progress
              type={progressType}
              width={progressWidth}
              percentage={progressnum}
              class={progressType == "circle" ? "progress-position" : ""}
            >
            </el-progress>
            <span>{progressTips}</span>
          </div>
        ) : null}
      </div>
    );
  },
  mounted() {
    // this.dcAutoInit();
  },
  watch: {
    progressValue: {
      handler(newVal, oldVal) {
        this.progressnum = newVal;
      }
    }
  },

};
</script>
<style lang="less" scoped>
.progress-position {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
