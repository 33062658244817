<script>
import UploadList from './upload-list';
import Upload from './upload';
import ElProgress from 'element-ui/packages/progress';
import Migrating from 'element-ui/src/mixins/migrating';

function noop() { }

export default {
  name: 'ElUpload',

  mixins: [Migrating],

  components: {
    ElProgress,
    UploadList,
    Upload
  },

  provide() {
    return {
      uploader: this
    };
  },

  inject: {
    elForm: {
      default: ''
    }
  },

  props: {
    progressWidth: {
      type: Number,
      default: 100
    },
    progressType: {
      type: String,
      default: "circle"
    },
    // 上传文件input的dom
    dcdom: {
      type: String,
      default: 'dcFile'
    },
    // 上传文件的初始化配置
    dcConfig: {
      type: Object
    },
    // 上传成功后的回调
    dcSuccess: {
      type: Function,
      default: noop
    },
    // 是否自动上传
    dcAutoUpload: {
      type: Boolean,
      default: true
    },
    // 单个文件大小
    filesSizeMax: {
      type: Number,
      default: 1024
    },
    action: {
      type: String,
      required: false
    },
    headers: {
      type: Object,
      default() {
        return {};
      }
    },
    data: Object,
    multiple: Boolean,
    name: {
      type: String,
      default: 'file'
    },
    drag: Boolean,
    dragger: Boolean,
    withCredentials: Boolean,
    showFileList: {
      type: Boolean,
      default: true
    },
    accept: String,
    type: {
      type: String,
      default: 'select'
    },
    beforeUpload: Function,
    beforeRemove: Function,
    onRemove: {
      type: Function,
      default: noop
    },
    onChange: {
      type: Function,
      default: noop
    },
    onPreview: {
      type: Function
    },
    onSuccess: {
      type: Function,
      default: noop
    },
    onProgress: {
      type: Function,
      default: noop
    },
    onError: {
      type: Function,
      default: noop
    },
    fileList: {
      type: Array,
      default() {
        return [];
      }
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    listType: {
      type: String,
      default: 'text' // text,picture,picture-card
    },
    httpRequest: Function,
    disabled: Boolean,
    limit: Number,
    onExceed: {
      type: Function,
      default: noop
    }
  },

  data() {
    return {
      // tryfasd:'',
      // 上传状态
      dcProgressStatus: '',
      // 上传初始进度
      progressValue: 0,
      //上传进度条
      dcProgressIsok: false,
      // 上传进度
      progressnum: 0,
      // 金山云上传文件信息(type,size,name)
      dcUploadData: {},
      // 金山云初始化函数
      dcUploadInit: null,
      uploadFiles: [],
      dragOver: false,
      draging: false,
      tempIndex: 1,
      progressTips: ""
    };
  },

  computed: {
    uploadDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    dcprogress() {
      return this.dcProgressIsok
    }
  },

  watch: {
    fileList: {
      immediate: true,
      handler(fileList) {
        this.uploadFiles = fileList.map(item => {
          item.uid = item.uid || (Date.now() + this.tempIndex++);
          item.status = item.status || 'success';
          return item;
        });
      }
    }
  },

  methods: {
    // 金山云初始化get_config
    dcUploadConfig() {
      let obj = {
        key: this.dcConfig.key,
        notify_url: this.dcConfig.notify_url,
        id: this.dcdom
      }
      this.dcUploadInit = dcupload(obj);
    },
    // 金山云获取上传文件的信息(size,name,type)
    dcChange(file) {
      // this.dcUploadData.jsy_file_name = file.name;
      // this.dcUploadData.jsy_file_type = file.type;
      // this.dcUploadData.jsy_file_size = file.size;
      // var extIndex = parseInt(file.name.lastIndexOf(".")) + 1;
      // this.dcUploadData.jsy_file_ext = file.name.substring(extIndex);
    },
    // 金山云手动上传按钮
    dcSubmit(dom, obj) {
      var fileInfo = document.getElementById(dom);
      if (!fileInfo.files[0]) return this.$alert("请选择上传文件!");
      this.dcUploadHttp(dom);
    },
    // 金山云上传请求
    dcUploadHttp(dom, length) {
      let userId = localStorage.getItem("id");
      let fileInfo = document.getElementById(dom);
      let file = fileInfo.files[0];
      let data = {};
      // data.jsy_file_name = file.name;
      data.uid = userId;
      data.length = String(length || 0);
      // var extIndex = parseInt(file.name.lastIndexOf(".")) + 1;
      // data.jsy_file_ext = file.name.substring(extIndex);
      this.$emit('dc-progress', 'err');
      let _this = this;
      this.dcUploadInit.start(
        {
          data,
          // 上传进度
          on_progress(index, count) {
            _this.dcProgressStatus = 'ready';
            _this.dcProgressIsok = true;
            let tips = "正在上传...";
            if (index == count) {
              _this.progressnum = 100;
              tips = '正在合并数据中...';
              _this.progressTips = tips;
            } else {
              _this.progressnum = Number(((index / count) * 100).toFixed(0));
              tips ='正在上传...' +  _this.progressnum + '%';
            }
            _this.$emit("dc-progress", tips);
          }
        },
        // 上传结束
        function (err, results) {
          _this.progressTips = ""
          _this.progressnum = 0;
          _this.progressValue = 0;
          _this.dcProgressIsok = false;
          _this.dcProgressStatus = 'success';
          _this.$refs['upload'].progressEnd();
          if (err) return _this.$emit('dc-success', err);
          let successData = JSON.stringify(results);
          let returnData = eval("(" + successData + ")");
          // returnData.upload.CompleteMultipartUploadResult.ETag = `"${returnData.upload.CompleteMultipartUploadResult.Location}"`;
          return _this.$emit('dc-success', returnData);
        }
      );
    },
    // 金山云上传成功回调
    handleDcSuccess(res, rawFile) {
      const file = this.getFile(rawFile);
      if (file) {
        file.status = 'success';
        file.response = res;
        this.dcSuccess(res, file, this.uploadFiles);
      }
    },
    handleStart(rawFile) {
      rawFile.uid = Date.now() + this.tempIndex++;
      let file = {
        status: 'ready',
        name: rawFile.name,
        size: rawFile.size,
        percentage: 0,
        uid: rawFile.uid,
        raw: rawFile
      };

      if (this.listType === 'picture-card' || this.listType === 'picture') {
        try {
          file.url = URL.createObjectURL(rawFile);
        } catch (err) {
          console.error('[Element Error][Upload]', err);
          return;
        }
      }

      this.uploadFiles.push(file);
      this.onChange(file, this.uploadFiles);
    },
    handleProgress(ev, rawFile) {
      const file = this.getFile(rawFile);
      this.onProgress(ev, file, this.uploadFiles);
      file.status = 'uploading';
      file.percentage = ev.percent || 0;
    },
    handleSuccess(res, rawFile) {
      const file = this.getFile(rawFile);
      if (file) {
        file.status = 'success';
        file.response = res;
        this.onSuccess(res, file, this.uploadFiles);
        this.onChange(file, this.uploadFiles);
      }
    },
    handleError(err, rawFile) {
      const file = this.getFile(rawFile);
      const fileList = this.uploadFiles;

      file.status = 'fail';

      fileList.splice(fileList.indexOf(file), 1);

      this.onError(err, file, this.uploadFiles);
      this.onChange(file, this.uploadFiles);
    },
    handleRemove(file, raw) {
      this.dcUploadInit.pause();
      if (raw) {
        file = this.getFile(raw);
      }
      let doRemove = () => {
        // this.abort(file);
        let fileList = this.uploadFiles;
        fileList.splice(fileList.indexOf(file), 1);
        this.onRemove(file, fileList);
      };
      if (!this.beforeRemove) {
        doRemove();
      } else if (typeof this.beforeRemove === 'function') {
        const before = this.beforeRemove(file, this.uploadFiles);
        if (before && before.then) {
          before.then(() => {
            doRemove();
          }, noop);
        } else if (before !== false) {
          doRemove();
        }
      }

    },
    getFile(rawFile) {
      let fileList = this.uploadFiles;
      let target;
      fileList.every(item => {
        target = rawFile.uid === item.uid ? item : null;
        return !target;
      });
      return target;
    },
    abort(file) {
      this.$refs['upload-inner'].abort(file);
    },
    clearFiles() {
      this.uploadFiles = [];
    },
    submit() {
      this.uploadFiles
        .filter(file => file.status === 'ready')
        .forEach(file => {
          this.$refs['upload-inner'].upload(file.raw);
        });
    },
    getMigratingConfig() {
      return {
        props: {
          'default-file-list': 'default-file-list is renamed to file-list.',
          'show-upload-list': 'show-upload-list is renamed to show-file-list.',
          'thumbnail-mode': 'thumbnail-mode has been deprecated, you can implement the same effect according to this case: http://element.eleme.io/#/zh-CN/component/upload#yong-hu-tou-xiang-shang-chuan'
        }
      };
    }
  },
  mounted() {
    this.dcUploadConfig()
  },
  beforeDestroy() {
    this.uploadFiles.forEach(file => {
      if (file.url && file.url.indexOf('blob:') === 0) {
        URL.revokeObjectURL(file.url);
      }
    });
  },

  render(h) {
    let uploadList;

    if (this.showFileList) {
      uploadList = (
        <UploadList
          dcProgressStatus={this.dcProgressStatus}
          progressnum={this.progressnum}
          dcprogress={this.dcprogress}
          disabled={this.uploadDisabled}
          listType={this.listType}
          files={this.uploadFiles}
          on-remove={this.handleRemove}
          handlePreview={this.onPreview}>
        </UploadList>
      );
    }

    const uploadData = {
      props: {
        progressTips: this.progressTips,
        progressValue: this.progressnum,
        showFileList: this.showFileList,
        // 金山云初始化配置
        dcConfig: this.dcConfig,
        // 金山云input节点id
        dcdom: this.dcdom,
        progressWidth: this.progressWidth,
        progressType: this.progressType,
        // 金山云上传成功后的回调
        'dc-success': this.handleDcSuccess,
        // 金山云是否自动上传
        dcAutoUpload: this.dcAutoUpload,
        dcUploadData: this.dcUploadData,
        dcUploadInit: this.dcUploadInit,
        type: this.type,
        drag: this.drag,
        action: this.action,
        filesSizeMax: this.filesSizeMax,
        multiple: this.multiple,
        'before-upload': this.beforeUpload,
        'with-credentials': this.withCredentials,
        headers: this.headers,
        name: this.name,
        data: this.data,
        accept: this.accept,
        fileList: this.uploadFiles,
        autoUpload: this.autoUpload,
        listType: this.listType,
        disabled: this.uploadDisabled,
        limit: this.limit,
        'on-exceed': this.onExceed,
        'on-start': this.handleStart,
        'on-progress': this.handleProgress,
        'on-success': this.handleSuccess,
        'on-error': this.handleError,
        'on-preview': this.onPreview,
        'on-remove': this.handleRemove,
        'http-request': this.httpRequest
      },
      ref: 'upload-inner'
    };
    this.tryfasd = uploadData.ref

    const trigger = this.$slots.trigger || this.$slots.default;
    const uploadComponent = <upload {...uploadData} ref="upload">{trigger}</upload>;
    return (
      <div>
        {this.listType === 'picture-card' ? uploadList : ''}
        {
          this.$slots.trigger
            ? [uploadComponent, this.$slots.default]
            : uploadComponent
        }
        {this.$slots.tip}
        {this.listType !== 'picture-card' ? uploadList : ''}
      </div>
    );
  }
};
</script>
